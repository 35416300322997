html,
body {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  height: 100%;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  min-height: 100%;
  // overflow: hidden;
  line-height: 1.3;
  width: 100%;
  color: $black;
  font-family: $montserrat !important;
}

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: $font-weight-light !important;
  margin: 0px 0px 10px;
}

.btn:focus,
.btn:active,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

p {
  margin: 0px 0px 15px;
}

div {
  transition: all .3s linear;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
}