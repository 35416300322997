@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800");

$montserrat: "Montserrat", sans-serif;

$thin: 100;
$exlight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$exbold: 800;
$black: 900;
