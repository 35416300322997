#profile-info {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

#profile-summary {
  width: 50%;
  text-align: center;
  margin-top: 2rem;
}

#profile-wrap {
  background: transparent;
  -moz-box-shadow: 0 0 20px transparent;
  -webkit-box-shadow: 0 0 20px transparent;
  box-shadow: 0 0 20px transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  right: 0;

  @media (max-width: $breakpoint-tablet) {
    display: block;
    right: 0;
  }

  @media (max-width: $breakpoint-phone) {
    display: block;
    bottom: -4em;
    right: 0;
    text-align: center;

    .info {
      transform: none;
    }
  }
}