.explorations {

  img {
    border-radius: 15px;
  }

  .thumbnail-holder {
    position: relative;

    &:hover .overlay {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    border-radius: 15px;

    &.bg-1 {
      background-color: #333333;
    }

    &.bg-2 {
      background-color: #75ac35;
    }

    &.bg-3 {
      background-color: #0c71a2;
    }

    &.bg-4 {
      background-color: #dff8f2;
    }

    &.bg-5 {
      background-color: #e6e7e8;
    }

    &.bg-6 {
      background-color: #b8f8e8;
    }
  }

  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .link-unstyled,
  .link-unstyled:link {
    color: inherit;
    text-decoration: inherit;
    transition: all .3s ease;
  }

  .link-unstyled:hover {
    font-weight: 600;
    color: rgba(0, 0, 0, .7)
  }
}