.content {
  position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  height: 160px;
  overflow: hidden;

  font-family: $montserrat, sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #191919;
  margin: -3rem auto;

  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 50px;
    padding: 0 40px;

    &:before {
      content: '{';
      left: 0;
    }

    &:after {
      content: '}';
      position: absolute;
      right: 0;
    }

    &:after,
    &:before {
      position: absolute;
      top: 0;

      color: #98d047;
      font-size: 42px;
      line-height: 40px;

      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &__text {
      display: inline;
      float: left;
      margin: 0;
    }

    &__list {
      margin-top: 0;
      padding-left: 230px;
      text-align: left;
      list-style: none;
      color: #6e6e6e;
      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 51px;
        margin: 0;

        a {
          color: #8db55f;
          text-decoration: none;

          &:hover {
            color: #fff;
              background-color: #75ac35;
              padding-left: .5rem;
              padding-right: .5rem;
          }
        }
      }
    }
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

// GLOBAL
@media (max-width: $breakpoint-phone) {

  .content {
    height: 160px;
    font-size: 30px;
    line-height: 45px;
    padding: 0 15px;
  }

  .content__container {
    padding: 0 20px !important;
    height: 40px;
  }

  .content__container__list {
    padding-left: 160px;
  }

  .content__container__list__item {
    line-height: 45px;
  }
}